/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect,useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.scss"
import { Helmet } from "react-helmet"

const Layout = (props) => {
  const { children, page, pageContext } = props;
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [mensajeFooter, setMensajeFooter] = useState("")
  useEffect(() => {
    if(pageContext){
    if(page == 'poligonos'){
      setMensajeFooter('"'+pageContext.messages['seleccionamos-los-mejores']+'"');
    }else if(page == 'suelos'){
      setMensajeFooter('"'+ pageContext.messages['nuestro-manejo-de-viniedos']+'"<br/><br/><span style="font-size:1.5rem;">-'+ pageContext.messages['enologa-andrea-ferreyra'] +'- </span>');
    }else{
      setMensajeFooter(pageContext.messages['la-primera-bodega-del-valle']+"<br/>"+pageContext.messages['mas-de-ciento-treinta'])
    }
    if(!navigator.onLine){
      document.querySelector(".video-wrapper").classList.add("d-none")
    }
  }
  }, [])
  return (
    <div id="wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>FINCA LA CELIA</title>
        <meta name="description" content="Plataforma Terroir" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} {...props} />
      <main className="main min-vh-100">{children}</main>
      <footer className="footer text-center d-flex align-items-center flex-content-center">
        <div className="w-100">
          <h3 dangerouslySetInnerHTML={{ __html:mensajeFooter}}></h3>
        </div>
        <div className="date w-100 position-absolute bottom-0">© Copyright {new Date().getFullYear()} - Finca La Celia - Mendoza, Argentina</div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
