import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import isologo from "../images/logo_la_celia.svg";

import SimpleLocalize from "../components/SimpleLocalize";
import { FormattedMessage } from "react-intl";

const Header = (props) => {
	const { siteTitle } = props
	let languagePath;
	if (props.pageContext && props.pageContext.language != props.pageContext.defaultLanguage) {
		languagePath = "/" + props.pageContext.language + "/"
	} else {
		languagePath = '/'
	}



	const switchIdioma = () => {
		localStorage.setItem('navegacionIdiomas', true)
		if (typeof window !== 'undefined') {
			if (languagePath == '/') {
				window.location.href = '/en' + window.location.pathname
			} else {
				window.location.href = window.location.pathname.replace('/en/', '/')
			}
		}
	}

	useEffect(() => {
		if (!localStorage.getItem('navegacionIdiomas')) {
			const language = 'en'; //(navigator.language || navigator.userLanguage).substr(0, 2);
			var str = window.location.pathname.split("/en")[1];
			/* if (language == 'en' && window.location.href.search('/en') == -1) {
				window.location.href = '/en' + str

			} else if (language == 'es' && window.location.href.search('/en') != -1) {
				window.location.href = str;
			} */
		}
	}, [])

	return (
		<SimpleLocalize {...props}>
			<header id="head">
				<nav className="navbar navbar-expand-lg fixed-top">
					<div className="container">
						<div className="col-md-3">
							<h1 className="header-logo-wrapper" >
								<Link
									className="navbar-brand"
									to={languagePath}
									style={{
										color: `white`,
										textDecoration: `none`,
									}}
								>
									<img src={isologo} className="img-fluid" />
								</Link>
							</h1>
						</div>
						<div className="col-md-9">
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								-
							</button>
							<div className="collapse navbar-collapse  justify-content-end" id="navbarSupportedContent">
								<ul className="navbar-nav ml-auto">
									{/* clima */}
									<li className="nav-item">
										<Link to={`${languagePath}clima#head`} className="nav-link" activeClassName="active">
											<FormattedMessage id="clima" defaultMessage="clima" />
										</Link>
									</li>
									{/* varieties / la finca */}
									{/*<li className="nav-item">
										<Link to={`${languagePath}#head`} className="nav-link" activeClassName="active">
											<FormattedMessage id="la-finca" defaultMessage="la-finca" />
										</Link>
									</li>*/}
									{/* soil / suelo */}
									<li className="nav-item">
										<Link to={`${languagePath}suelo#head`} className="nav-link" activeClassName="active">
											<FormattedMessage id="suelo" defaultMessage="suelo" />
										</Link>
									</li>
									{/*poligonos / polygons */}
									<li className="nav-item">
										<Link to={`${languagePath}poligonos#head`} className="nav-link" activeClassName="active">
											<FormattedMessage id="poligonos" defaultMessage="poligonos" />
										</Link>
									</li>
									{/* vinos / wines */}
									<li className="nav-item">
										<Link to={`${languagePath}vinos#head`} className="nav-link" activeClassName="active">
											<FormattedMessage id="vinos" defaultMessage="vinos" />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</header></SimpleLocalize>)

}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
