import React from "react";
import {IntlProvider} from "react-intl";

function SimpleLocalize(props) {
  let language;
  let messages;
  if(props.pageContext){
    language = props.pageContext.language;
    messages = props.pageContext.messages;
  }
  return (
    <IntlProvider
      locale={language}
      messages={messages}>
      {props.children}
    </IntlProvider>
  );
}

export default SimpleLocalize;